import React from 'react';
import { Card } from '@material-ui/core';
import CSVMenu from './CSVMenu';
import JSONMenu from './JSONMenu';
export default function Export() {  
  return (    
    <Card>
      <CSVMenu />
      <JSONMenu />
    </Card>
  );
}