import { useEffect, useRef, useState } from 'react';

export function useDocumentClickListener(active, fn, ignoreRef = null) {
  const callback = e => {
    const clickTargetInIgnoreRef = ignoreRef !== null && ignoreRef.current.contains(e.target);
    if (!clickTargetInIgnoreRef) fn(e);
  };
  return useEffect(() => {
    if (active) document.addEventListener('click', callback);
    else document.removeEventListener('click', callback);
    return () => {
      document.removeEventListener('click', callback);
    };
  }, [active]);
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      tick();
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const useTimer = (every = 1) => {
  const getTime = () => Math.floor(Date.now() / (1000 * every));
  const [now, setNow] = useState(getTime());
  useEffect(() => {
    let x = now;
    const intervalId = setInterval(async () => {
      const newNow = getTime();
      if (x !== newNow) {
        x = newNow;
        setNow(x);
      }
    }, 100);
    return () => clearInterval(intervalId);
  }, [every]);
  return now;
};
