import React, { useState } from "react";
import { useInterval } from "utilities/hooks";
import useCMS from "views/Tools/useCMS";
import Axios from "axios";
import { Card, Typography, CardContent } from "@material-ui/core";

const countUsers = async (remote, params) => {  
  const {endpoint, auth} = remote;
  const res = await Axios.get(`${endpoint}/exports/users/`, { params, ...auth });
  return res.data.users && res.data.users.length;
};

export function UserCounter({eventId}) {
  const [count, setCount] = useState(0);
  const remote = useCMS();
  useInterval(async () => {
    setCount(await countUsers(remote, {eventId}));
  }, 10000);

  return (
    <Card>
      <CardContent>
        <Typography>{count} total attendees</Typography>
      </CardContent>
    </Card>
  );
}