import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  FormControlLabel,
} from '@material-ui/core';
import pluralize from 'pluralize';
import { DateTimePicker } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import Toolbar from './components/Toolbar/Toolbar';
import { modelApi, useModelStore, isAttributeHidden } from 'services/ModelService';
import { capitalize, localToUtc, utcToLocal } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const ModelBooleanField = params => {
  const { instance, attributeName } = params;

  const label = capitalize(attributeName);

  const handleChange = (event, value) => {
    instance[attributeName] = value === true;
    modelApi.setState({ instance: { ...instance } });
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={instance[attributeName]} onChange={handleChange} color="primary" />}
      label={label}
    />
  );
};

const ModelFileField = params => {
  const { instance, attributeName } = params;

  const label = capitalize(attributeName);

  const handleFileClick = () => {
    const input = document.createElement('input');
    input.onchange = e => {
      const file = e.target.files[0];
      instance[attributeName] = file;
      instance['fileName'] = file.name;
      modelApi.setState({ instance: { ...instance } });
    };
    input.type = 'file';
    input.click();
  };

  return (
    <Button color="secondary" variant="contained" onClick={handleFileClick}>
      {label}
    </Button>
  );
};

const ModelTextField = params => {
  const { instance, attribute, attributeName } = params;

  const label = capitalize(attributeName);

  const handleChange = event => {
    instance[attributeName] = event.target.value;
    modelApi.setState({ instance: { ...instance } });
  };

  let inputType;

  switch(attribute.type) {
    case 'integer':
      inputType = 'number';
      break;
    case 'password':
    inputType = 'password';
    break;
      default:
        inputType = 'text';
  }

  return (
    <TextField
      fullWidth
      helperText={attribute.helperText}
      label={label}
      type={inputType}
      onChange={handleChange}
      value={instance[attributeName]}
      variant="outlined"
      multiline={attribute.type === 'text'}
      rows={5}
    />
  );
};

const ModelRelationField = params => {
  const { instance, attribute, attributeName } = params;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options != null && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const state = modelApi.getState();
      const options = attribute.options ? await attribute.options() : await state.options(pluralize(attribute.model));
      if (active) {
        setOptions(options.length === 0 ? null : options);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, attribute]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const label = capitalize(attributeName);

  const handleChange = (event, option) => {
    instance[attributeName] = option;
    modelApi.setState({ instance: { ...instance } });
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={option => {
        if (!option.id) {
          return 'none';
        }
        const getLabelKey = option =>
          Object.keys(option).find(
            a => a.includes('name') || a.includes('Name') || a.includes('headline') || a.includes('title'),
          ) || Object.keys(option).find(a => a !== 'id' && typeof option[a] === 'string');
        const foundLabel = option[getLabelKey(option)];
        const label = (attribute.label && attribute.label(option)) || foundLabel;
        return `ID:${option.id.toString()}` + (label ? ` ${label}` : '');
      }}
      options={options || []}
      loading={loading}
      value={instance[attributeName]}
      onChange={handleChange}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

const ModelDateTimeField = params => {
  const { instance, attributeName, timeZone } = params;

  const label = capitalize(attributeName);
  const localTime = utcToLocal(instance[attributeName], timeZone);

  const handleChange = timeStamp => {
    instance[attributeName] = localToUtc(timeStamp, timeZone);
    modelApi.setState({ instance: { ...instance } });
  };

  const timeZoneLabel = timeZone ? timeZone : "UTC";

  return (
    <DateTimePicker
      label={`${label} (${timeZoneLabel})`}
      inputVariant="outlined"
      value={localTime}
      onChange={handleChange}
      minutesStep={1}
      fullWidth
    />
  );
};

function Rows({ schema, instance }) {
  const rows = [];
  const keys = Object.keys(schema.attributes);
  for (const key of keys) {
    const attribute = schema.attributes[key];

    const hidden = isAttributeHidden(attribute, 'form');
    if (hidden) {
      continue;
    }

    let type = attribute.type;
    if (type == null) {
      type = 'model';
    }

    const timeZone = instance.event ? instance.event.timeZone : instance.timeZone;

    let row = null;

    if (attribute.component && typeof attribute.component === "function") {
      row = <attribute.component instance={instance} attribute={attribute} attributeName={key} />;
    } else {
      switch (type) {
        case 'boolean':
          row = <ModelBooleanField instance={instance} attribute={attribute} attributeName={key} />;
          break;
        case 'file':
          row = <ModelFileField instance={instance} attribute={attribute} attributeName={key} />;
          break;
        case 'integer':
        case 'text':     
        case 'string':
        case 'password':
          row = <ModelTextField instance={instance} attribute={attribute} attributeName={key} />;
          break;
        case 'model':
          row = <ModelRelationField instance={instance} attribute={attribute} attributeName={key} />;
          break;
        case 'datetime':
          row = (
            <ModelDateTimeField instance={instance} attribute={attribute} attributeName={key} timeZone={timeZone} />
          );
          break;
        default:
          continue;
      }
    }

    if (row) {
      rows.push(
        <Grid key={key} item md={6} xs={12}>
          {row}
        </Grid>,
      );
      rows.push(<Grid key={key + '_'} item md={6} xs={12} />);
    }
  }
  return rows;
}

const Form = props => {
  const { className, instance, ...rest } = props;

  const history = useHistory();

  const classes = useStyles();

  const schema = useModelStore(state => state.schema);

  const handleSave = async () => {
    const state = modelApi.getState();
    if (instance.id != null) {
      await state.update(instance);
    } else {
      await state.create(instance);
    }
    history.push(`/${schema.routeName}`);

    if(schema.kind === "singleType") {
      // TODO: quick win to give feedback to the user
      window.location.reload();
    }
  };

  const rows = <Rows schema={schema} instance={instance} />;
  const actionName = instance.id ? 'Update' : 'Create';

  return (
    <div className={classes.root}>
      <Toolbar headline={actionName} hasSearch={false} />
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <CardHeader title={capitalize(schema.info.name)} />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              {rows}
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button color="primary" variant="contained" onClick={handleSave}>
              {actionName}
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  );
};

const ModelForm = props => {
  const { id } = props;

  const schema = useModelStore(state => state.schema);
  const instance = useModelStore(state => state.instance);

  useEffect(() => {
    const state = modelApi.getState();
    if (id === 'new') {
      state.new();
    } else {
      state.get(id);
    }
    return () => {
      state.cancel();
    };
  }, [id]);

  return schema && instance ? <Form schema={schema} instance={instance} /> : null;
};

export default ModelForm;
