import create from 'zustand';
import { cmsApi } from 'services/CmsService';
import axios from 'axios';
import userStore from 'storage/user';

function context() {
  const { endpoint } = cmsApi.getState();
  const token = userStore.getToken();
  const auth = { headers: { Authorization: `Bearer ${token}` } };
  return { endpoint, auth };
}

export const [useTranslationStore, translationApi] = create((set, get) => ({
  languages: [],
  translationKeys: [],

  updateTranslationKeys: async () => {
    const { endpoint, auth } = context();
    const tresult = await axios.get(`${endpoint}/translation-keys`, auth);
    set({translationKeys: tresult.data && tresult.data.translationKeys});
  },

  poll: async () => {
    const { endpoint, auth } = context();
    const lresult = await axios.get(`${endpoint}/languages`, auth);
    const languages = lresult.data && lresult.data.map(a => a.iso);
    set({ languages });
  },
}));
