import { List, ListItem, ListSubheader } from "@material-ui/core";
import { LocalizedText } from "components/LocalizedText";
import React, { useEffect } from "react";
import { modelApi } from "services/ModelService";
import { translationApi, useTranslationStore } from 'services/TranslationService';

export default function LocalizedTextList(props) {
  useEffect(() => {
    translationApi.getState().updateTranslationKeys();
  }, []);
  const translationKeys = useTranslationStore(state => state.translationKeys);
  const { instance, attributeName } = props;
  const attribute = instance[attributeName] || {};
  return (
    <List subheader={
      <ListSubheader>{attributeName}</ListSubheader>
    }>
      {translationKeys.map((translationKey, i) => {
        const textData = attribute[translationKey] || '';
        return(
          <ListItem key={i}>
            <LocalizedText label={translationKey} data={textData} callback={d => {
              if(!instance[attributeName]){
                instance[attributeName] = attribute;
              }
              if(!instance[attributeName][translationKey]){
                instance[attributeName][translationKey] = textData;
              }
              instance[attributeName][translationKey] = d;
              modelApi.setState({ instance: { ...instance } });
            }} />
          </ListItem>
        );
      })}
    </List>
  );
}
