import React from 'react';
import Axios from 'axios';
import { getCSV } from 'utilities/csv';
import { saveFile } from 'utilities/fileio';
import useCMS from 'views/Tools/useCMS';
import { CardHeader, Divider, CardActions, Button } from '@material-ui/core';


const exportCSVUsers = async (remote, params) => {
  const {endpoint, auth} = remote;
  const res = await Axios.get(`${endpoint}/exports/users/`, { params, ...auth });
  const elements = res.data.users.map(user => {
    return {
      first_name: user.forename, 
      last_name: user.surname, 
      email: user.email, 
      CampaignAsset: user.landing_url, 
      company: user.company, 
      phone: user.phoneNumber
    };   
  });
  const csv = getCSV([
    'first_name', 
    'last_name', 
    'email', 
    'CampaignAsset', 
    'company', 
    'phone'
  ], elements);
  const now = new Date(Date.now());
  saveFile(csv, `users${now.toISOString()}.csv`, 'text/plain');
};

const exportCSVMessages = async (remote, params) => {
  const {endpoint, auth} = remote;
  const res = await Axios.get(`${endpoint}/exports/messages/`, { params, ...auth });
  const elements =  res.data.messages.map(message => {
    const user = message.user;
    return {
      first_name: user.forename, 
      last_name: user.surname, 
      email: user.email, 
      text: message.text,
      chat: message.chat.type
    };
  });
  const header = [
    'first_name', 
    'last_name', 
    'email', 
    'text',
    'chat',
  ];
  const csv = getCSV(header, elements);
  const now = new Date(Date.now());
  saveFile(csv, `messages${now.toISOString()}.csv`, 'text/plain');
};

export default function CSVMenu() {
    const remote = useCMS();
    return (
        <form>
        <CardHeader title="Export CSV" subheader="export data" />
        <Divider />
        <CardActions>
          <Button color="primary" variant="outlined" onClick={() => exportCSVUsers(remote)}>
            Export All Users
          </Button>
          <Button color="primary" variant="outlined" onClick={() => exportCSVUsers(remote, {registration: 'simple' })}>
            Export Simple Registration Users
          </Button>
          <Button color="primary" variant="outlined" onClick={() => exportCSVUsers(remote, {registration: 'full' })}>
            Export Full Registration Users
          </Button>     
        </CardActions>
        <Divider />
        <CardActions>
          <Button color="primary" variant="outlined" onClick={() => exportCSVMessages(remote)}>
              Export All Messages
            </Button>  
            <Button color="primary" variant="outlined" onClick={() => exportCSVMessages(remote, {chat: 'private' })}>
              Export Private Messages
            </Button>          
            <Button color="primary" variant="outlined" onClick={() => exportCSVMessages(remote, {chat: 'public' })}>
              Export Public Messages
            </Button>     
        </CardActions>
      </form>
    );
}