import React from 'react';
import { MenuItem } from '@material-ui/core';

import { DropdownField } from 'components/DropdownField';
import { ProviderPayload } from 'components/ProviderPayload';
import { TimezoneAutocomplete } from 'components/TimezoneAutocomplete';

import { modelApi } from 'services/ModelService';
import { eventApi } from 'services/EventService';
import { userApi } from 'services/UserService';
import { LanguageField } from 'components/LanguageField';
import LocalizedTextList from 'components/LocalizedTextList';

const hiddenInList = {
  form: false,
  list: true,
};
function createEventAttribute() {
  return {
    default: async () => {
      const state = eventApi.getState();
      return await state.pick();
    },
    valueInTable: (instance, key) => {
      return instance[key] ? instance[key].name : '-';
    },
    label: option => option.name,
  };
}

function createHeadlineAttribute() {
  return {
    valueInTable: (instance, key) => {
      return instance[key] ? instance[key].headline : '-';
    },
    label: option => option.headline,
  };
}

function createUserAttribute(filter) {
  return {
    default: () => {
      const state = userApi.getState();
      return state.user;
    },
    options: async () => {
      const state = modelApi.getState();
      return await state.options('users', filter);
    },
    label: option => {
      return option.id != null ? option.searchName : 'none';
    },
    valueInTable: (instance, key) => {
      return instance[key] ? instance[key].searchName : '-';
    },
  };
}

function createAssetAttribute(filter, media) {
  return {
    options: () => {
      const state = modelApi.getState();
      return state.options('assets', 'filter_eq=' + filter);
    },
    valueInTable: (instance, key) => {
      return { media, asset: instance[key] };
    },
    label: option => option.fileName,
  };
}

function createLanguageAttribute() {
  return {
    default: null,
    component: LanguageField,
  };
}

function createAssetTable(filter, media) {
  return {
    apiName: 'assets',
    useFormData: true,
    filter: 'filter_eq=' + filter,
    attributes: {
      file: { type: 'file' },
      url: {
        hidden: false,
        valueInTable: instance => {
          return { media, asset: instance };
        },
      },
      filter: {
        default: () => filter,
      },
      language: createLanguageAttribute(),
    },
  };
}

function createTalkTable(filter, location) {
  return {
    apiName: 'talks',
    filter,
    attributes: {
      event: createEventAttribute(),
      speaker: createUserAttribute('role.type=speaker&role.type=expert&isHidden=false'),
      teaser: createAssetAttribute('talk-teaser', 'image'),
      providerType: {
        default: 'HLS_STREAM',
        component: props => {
          const locationOptions = [
            'HLS_STREAM',
          ];
          const options = locationOptions.map((value, i) => (
            <MenuItem key={i} value={value}>
              {value}
            </MenuItem>
          ));
          return <DropdownField {...props} options={options} />;
        },
      },
      providerPayload: {
        component: props => {
          return <ProviderPayload {...props} />;
        },
      },
      hasSentReminder: {
        hidden: true,
      },
      location,
      description: {
        hidden: hiddenInList,
      },
      language: createLanguageAttribute(),
    },
  };
}

const config = {
  events: {
    attributes: {
      shortname: {
        helperText: 'Short and lower case identifier. Only a-z, 0-9 or underscore allowed.',
      },
      supportUser: createUserAttribute('role.type=supporter&isHidden=false'),
      timeZone: {
        component: props => <TimezoneAutocomplete {...props} />,
      },
    },
  },
  slots: {
    attributes: {
      event: createEventAttribute(),
      isBookedUp: {
        hidden: true,
      },
    },
  },
  'slot-topics': {
    attributes: {
      event: createEventAttribute(),
      language: createLanguageAttribute(),
    },
  },
  users: {
    createHidden: false,
    attributes: {
      email: {
        hidden: false,
        configurable: true,
        type: 'string',
      },
      username: {
        hidden: false,
      },
      role: {
        hidden: {
          form: () => {
            const state = userApi.getState();
            const userRole = state.user.role.type;
            // only the admin can change the role
            const isAdmin = userRole === 'admin';
            return !isAdmin;
          },
          list: false,
        },
        options: async () => {
          const userState = userApi.getState();
          const userRole = userState.user.role.type;
          const modelState = modelApi.getState();
          let result = await modelState.options('users-permissions/roles');
          let roles = result.roles;
          roles = roles.filter(role => {
            return role.type !== 'public';
          });
          if (userRole !== 'admin') {
            roles = roles.filter(role => {
              return role.type !== 'admin';
            });
          }
          return roles;
        },
        label: option => option.type,
        valueInTable: (instance, key) => {
          return instance[key] ? instance[key].type : '-';
        },
      },
      confirmed: {
        hidden: true,
      },
      password: {
        hidden: hiddenInList,
      },
      provider: {
        hidden: true,
      },
      resetPasswordToken: {
        hidden: true,
      },
      company: {
        hidden: hiddenInList,
      },
      title: {
        hidden: hiddenInList,
      },
      country: {
        hidden: hiddenInList,
      },
      state: {
        hidden: hiddenInList,
      },
      linkedInUrl: {
        hidden: hiddenInList,
      },
      description: {
        hidden: hiddenInList,
      },
      profilePicture: {
        hidden: true,
      },
      searchName: {
        hidden: true,
      },
      isOnline: {
        hidden: true,
      },
      activity: {
        hidden: true,
      },
      hasReceivedPrivateMessageMail: {
        hidden: true,
      },
      hasNotifications: {
        hidden: true,
      },
    },
    searchFields: ['searchName'],
  },
  'resource-hubs': {
    attributes: {
      event: createEventAttribute(),
      teaser: createAssetAttribute('resource-teaser', 'image'),
      language: createLanguageAttribute(),
      location: {
        default: 'workshop',
        component: props => {
          const options = [
            'roof',
            'bar',
            'workshop',
            'lookout',
            'terrace',
            'hub01',
            'hub02',
            'hub03',
            'stageLeft01',
          ].map((value, i) => (
            <MenuItem key={i} value={value}>
              {value}
            </MenuItem>
          ));
          return <DropdownField {...props} options={options} />;
        },
      },
      type: {
        default: 'RESOURCE',
        component: props => {
          const options = ['LINK', 'IMAGE', 'VIDEO', 'RESOURCE', 'PDF', 'IFRAME'].map((value, i) => (
            <MenuItem key={i} value={value}>
              {value}
            </MenuItem>
          ));
          return <DropdownField {...props} options={options} />;
        },
      },
    },
  },
  resources: {
    attributes: {
      category: createHeadlineAttribute(),
      teaser: createAssetAttribute('resource-teaser', 'image'),
      type: {
        default: 'LINK',
        component: props => {
          const options = ['LINK', 'IFRAME'].map((value, i) => (
            <MenuItem key={i} value={value}>
              {value}
            </MenuItem>
          ));
          return <DropdownField {...props} options={options} />;
        },
      },
    },
  },
  'resource-teasers': createAssetTable('resource-teaser', 'image'),
  'resource-videos': createAssetTable('resource-video', 'video'),
  'resource-pdfs': createAssetTable('resource-pdf', 'pdf'),
  demos: {
    attributes: {
      event: createEventAttribute(),
      teaser: createAssetAttribute('demo-teaser', 'image'),
      location: {
        component: props => {
          const locationOptions = ['demo01', 'demo02', 'demo03', 'demo04'];
          const options = locationOptions.map((value, i) => (
            <MenuItem key={i} value={value}>
              {value}
            </MenuItem>
          ));
          return <DropdownField {...props} options={options} />;
        },
      },
      language: createLanguageAttribute(),
    },
  },
  'demo-contents': {
    attributes: {
      demo: createHeadlineAttribute(),
      video: createAssetAttribute('demo-video', 'video'),
      teaser: createAssetAttribute('demo-teaser', 'image'),
      language: createLanguageAttribute(),
    },
  },
  'demo-videos': createAssetTable('demo-video', 'video'),
  'demo-teasers': createAssetTable('demo-teaser', 'image'),
  'resource-categories': {
    attributes: {
      hub: createHeadlineAttribute(),
    },
  },
  talks: createTalkTable('location_eq=stage01', {
    default: 'stage01',
    hidden: true,
  }),
  'talk-teasers': createAssetTable('talk-teaser', 'image'),
  languages: {
    attributes: {
      translations: {
        component: props => <LocalizedTextList {...props} />,
      },
    },
  },
  'product-meshes': createAssetTable('product-mesh', 'glb'),
  'district-meshes': createAssetTable('district-mesh', 'glb'),
  'district-textures': createAssetTable('district-texture', 'image'),
  quizzes: {
    attributes: {
      language: createLanguageAttribute(),
    },
  },
  products: {
    attributes: {
      language: createLanguageAttribute(),
    },
  },

  // single types//
  application: {
    attributes: {
      defaultEvent: createEventAttribute(),
    },
  },
};

export default config;
