import React, { useState } from 'react';
import { FormControl, TextField, ListItem, List, ListItemText, Collapse } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

export function LocalizedText({ data, label, callback }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <FormControl fullWidth>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          <ListItem>
            <TextField
              fullWidth
              type={'text'}
              onChange={event => {
                const v = event.target.value;
                data = v;
                callback(data);
              }}
              value={data}
              variant="outlined"
              multiline={true}
              rows={5}
            />
          </ListItem>
        </List>
      </Collapse>
    </FormControl>
  );
}
