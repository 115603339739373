const Emitter = require('component-emitter');
const schemapack = require('schemapack');

const eventCoders = [];
function addCoder(event, dataSchema) {
  if (dataSchema) {
    const coder = schemapack.build({ _id: 'uint8', data: dataSchema });
    eventCoders.push({ event, coder });
  } else {
    const coder = schemapack.build({ _id: 'uint8' });
    eventCoders.push({ event, coder });
  }
}
function Encoder() {}
Encoder.prototype.encode = function(packet, callback) {
  try {
    let binarySend = false;
    if (packet.data) {
      const eventCoderIndex = eventCoders.findIndex(eventCoder => eventCoder.event === packet.data[0]);
      if (eventCoderIndex > -1) {
        const eventCoder = eventCoders[eventCoderIndex];
        const binary = eventCoder.coder.encode({ _id: eventCoderIndex, data: packet.data[1] });
        binarySend = true;
        callback([binary]);
      }
    }
    if (!binarySend) {
      const reduced = { ...packet };
      reduced.d = reduced.data;
      delete reduced.data;
      delete reduced.nsp;
      delete reduced.options;
      if (reduced.type === 2) delete reduced.type;
      return callback([JSON.stringify(reduced)]);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('catched', JSON.stringify(packet, null, 2));
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

function Decoder() {}

Emitter(Decoder.prototype);

Decoder.prototype.add = function(obj) {
  try {
    if (typeof obj === 'string') {
      const packet = JSON.parse(obj);
      if (packet.type === undefined && packet.d[0] === 'schema') {
        const coders = packet.d[1];
        coders.forEach(coder => {
          addCoder(coder[0], coder[1]);
        });
      } else {
        if (packet.type === undefined) packet.type = 2;
        packet.data = packet.d;
        delete packet.d;
        packet.nsp = '/';
        this.emit('decoded', packet);
      }
    } else {
      const view = new Uint8Array(obj);
      const eventCoderIndex = view[0];
      const eventCoder = eventCoders[eventCoderIndex];
      const decoded = eventCoders[eventCoderIndex].coder.decode(obj);
      const packet = {};
      packet.type = 2;
      packet.data = [eventCoder.event, decoded.data];
      packet.nsp = '/';
      this.emit('decoded', packet);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('catched', JSON.stringify(obj, null, 2));
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

Decoder.prototype.destroy = function() {};

export { Encoder, Decoder };
