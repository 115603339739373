import React, { useEffect } from 'react';
import { MenuItem } from '@material-ui/core';
import { DropdownField } from 'components/DropdownField';
import { useTranslationStore, translationApi } from 'services/TranslationService';

export function LanguageField(props) {
  useEffect(() => {
    translationApi.getState().poll();
  }, []);
  const languages = useTranslationStore(state => state.languages);
  const options = languages.map((value, i) => (<MenuItem key={i} value={value}>{value}</MenuItem>));
  const withNone = [ (<MenuItem key={-1} value={''}>{'None'}</MenuItem>), ...options]
  return (<DropdownField {...props} options={withNone} displayEmpty />);
}
