import React from 'react';
import { Button, Card, CardActions, CardHeader, Divider } from '@material-ui/core';
import axios from 'axios';
import useCMS from '../../useCMS';
import { socketApi } from '../../../../services/SocketService';

const Users = () => {
  const { endpoint, auth } = useCMS();

  const updateSeachName = async () => {
    const allUsersResult = await axios.get(`${endpoint}/users?_limit=-1`, auth);
    for (let i = 0; i < allUsersResult.data.length; i++) {
      const user = allUsersResult.data[i];
      const userUpdate = { surname: user.surname, forename: user.forename };
      const oldSearchName = user.searchName;
      try {
        const updateResult = await axios.put(`${endpoint}/users/${user.id}`, userUpdate, auth);
        console.log(i + '/' + allUsersResult.data.length, oldSearchName, '=>', updateResult.data.searchName);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const fixIsOnlineNull = async () => {
    const allUsersResult = await axios.get(`${endpoint}/users?_limit=-1`, auth);
    for (let i = 0; i < allUsersResult.data.length; i++) {
      const user = allUsersResult.data[i];
      if (user.isOnline === null) {
        try {
          const userUpdate = { isOnline: false };
          const updateResult = await axios.put(`${endpoint}/users/${user.id}`, userUpdate, auth);
          console.log(i + '/' + allUsersResult.data.length, '=>', updateResult.data.isOnline);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const fixIsHiddenNull = async () => {
    const allUsersResult = await axios.get(`${endpoint}/users?_limit=-1`, auth);
    for (let i = 0; i < allUsersResult.data.length; i++) {
      const user = allUsersResult.data[i];
      if (user.isHidden === null) {
        try {
          const userUpdate = { isHidden: false };
          const updateResult = await axios.put(`${endpoint}/users/${user.id}`, userUpdate, auth);
          console.log(i + '/' + allUsersResult.data.length, '=>', updateResult.data.isHidden);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  return (
    <Card>
      <form>
        <CardHeader title="Users" subheader="Manage Users" />
        <Divider />
        <CardActions>
          <Button color="primary" variant="outlined" onClick={updateSeachName}>
            Update SearchName
          </Button>
          <Button color="primary" variant="outlined" onClick={fixIsOnlineNull}>
            Fix isOnline null
          </Button>
          <Button color="primary" variant="outlined" onClick={fixIsHiddenNull}>
            Fix isHidden null
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              const { socket } = socketApi.getState();
              socket.emit('admin/clientsRequest');
              socket.once('admin/clientsResponse', result => {
                let count = 0;
                result.players.forEach(p => {
                  if (p.user) {
                    count++;
                    console.log(count, p.room, p.user.name, p);
                  }
                });
              });
            }}
          >
            List Players
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              const { socket } = socketApi.getState();
              socket.emit('admin/serverStatusRequest');
              socket.once('admin/serverStatusResponse', result => {
                console.log(result);
              });
            }}
          >
            Server Status
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default Users;
