import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
export default function Dropdown({value, label, options, callback = () => {} }) {
  const onChange = e => {
    const val = e.target.value;
    callback(val);
  }
  return (
    <FormControl fullWidth>
      <InputLabel shrink>{label}</InputLabel>
      <Select value={value} onChange={onChange} displayEmpty>
      {options.map(kv => (<MenuItem key={kv.key} value={kv.key}>{kv.value}</MenuItem>))}
      </Select>
    </FormControl>
  );
}