import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { saveFile } from 'utilities/fileio';
import useCMS from 'views/Tools/useCMS';
import { CardHeader, Divider, CardActions, Button, Box } from '@material-ui/core';
import { getURLParams } from 'utilities/url';
import Dropdown from '../Dropdown';
import { useEventStore, eventApi } from 'services/EventService';
import { UserCounter } from '../../UserCounter';

const getUserLandingUrl = activities => {
  return activities.find(a => {
    const isLanding = a.activity === 'landing';
    return isLanding;
  });
};

const exportUsers = async (remote, params) => {
  const { endpoint, auth } = remote;
  const res = await Axios.get(`${endpoint}/exports/users/`, { params, ...auth });
  const elements = res.data.users.map(user => {
    const landingData = user.activities && getUserLandingUrl(user.activities);
    const urlParams = landingData && getURLParams(landingData.url);
    return {
      user_id: user.id,
      first_name: user.forename,
      last_name: user.surname,
      email: user.email,
      organization: user.company,
      title: user.title,
      opt_in: true,
      registration_time: user.created_at,
      arrival_url: landingData && landingData.url,
      activities: user.activities,
      arrival_query: urlParams,
      registration_flow: urlParams && urlParams.simple,
      events_attended: user.eventsAttended,
      country: user.country,
      language: user.language,
      quiz_answers: user.quiz_answers,
      badgeIndex: user.badgeIndex,
    };
  });
  const now = new Date(Date.now());
  saveFile(JSON.stringify(elements), `users${now.toISOString()}.json`, 'text/json');
};

const exportMessages = async (remote, params) => {
  const { endpoint, auth } = remote;
  const res = await Axios.get(`${endpoint}/exports/messages/`, { params, ...auth });
  const elements = res.data.messages.map(message => {
    const user = message.user;
    return {
      first_name: user.forename,
      last_name: user.surname,
      email: user.email,
      text: message.text,
      chat: message.chat.type,
    };
  });
  const now = new Date(Date.now());
  saveFile(JSON.stringify(elements), `messages${now.toISOString()}.json`, 'text/json');
};

export default function JSONMenu() {
  const remote = useCMS();
  useEffect(() => {
    eventApi.getState().getEvents();
  }, []);
  const events = useEventStore(state => state.events);
  const options = events.map(e => {
    return { key: e.id, value: e.name };
  });
  const [eventId, setEventId] = useState(null);
  useEffect(() => {
    if (!eventId && options[0]) {
      setEventId(options[0].key);
    }
  }, [options]);

  return (
    <form>
      <CardHeader title="Export JSON" subheader="export data" />
      <Divider />
      <CardActions>
        <Box component="span" display="block" width={1}>
          {eventId && (
            <Dropdown
              label={'Event'}
              value={eventId}
              options={options}
              callback={a => {
                setEventId(a);
              }}
            />
          )}
        </Box>
        <Box component="span" display="block" width={1}>
          <Button color="primary" variant="outlined" onClick={() => exportUsers(remote, { eventId })}>
            Export Event Users
          </Button>
        </Box>
        <Box component="span" display="block" width={1}>
          {eventId && <UserCounter eventId={eventId} />}
        </Box>
      </CardActions>
      <Divider />
      <CardActions>
        <Button color="primary" variant="outlined" onClick={() => exportUsers(remote)}>
          Export All Users
        </Button>
      </CardActions>
      <Divider />
      <CardActions>
        <Button color="primary" variant="outlined" onClick={() => exportMessages(remote)}>
          Export All Messages
        </Button>
      </CardActions>
    </form>
  );
}
