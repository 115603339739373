import React from 'react';
import { TextField } from '@material-ui/core';
import capitalize from 'helpers/capitalize';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';

const timeZones = moment.tz.names();

export function TimezoneAutocomplete({ instance, attributeName }) {
  const [open, setOpen] = React.useState(false);

  const handleChange = (event, option) => {
    if (timeZones.includes(option)) {
      instance[attributeName] = option;
    }
  };

  const label = capitalize(attributeName);

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={timeZones}
      value={instance[attributeName]}
      onChange={handleChange}
      renderInput={params => <TextField {...params} label={label} variant="outlined" />}
    />
  );
}
