import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import RouteWithLayout from './components/RouteWithLayout/RouteWithLayout';

import { useUserStore } from 'services/UserService';
import Model from './views/Model/Model';
import Icons from './views/Icons/Icons';
import Typography from './views/Typography/Typography';
import Account from './views/Account/Account';
import Settings from './views/Settings/Settings';
import Tools from './views/Tools/Tools';
import SignIn from './views/SignIn/SignIn';
import NotFound from './views/NotFound/NotFound';
import Minimal from './layouts/Minimal/Minimal';
import Main from './layouts/Main/Main';

const Routes = () => {
  const isPending = useUserStore(state => state.loginState === 'PENDING');
  const isLoggedIn = useUserStore(state => state.loginState === 'LOGGED_IN');

  if (!isLoggedIn) {
    return (
      <Switch>
        <RouteWithLayout component={SignIn} exact layout={Minimal} path="/sign-in" />
        <RouteWithLayout component={Settings} exact layout={Main} path="/settings" />
        {!isPending && <Redirect to="/sign-in" />}
      </Switch>
    );
  }

  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <RouteWithLayout component={() => <div></div>} exact layout={Main} path="/dashboard" />
      <RouteWithLayout component={Model} exact layout={Main} path={['/events/:id', '/events']} modelName="events" />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/slots/topics/:id', '/slots/topics']}
        modelName="slot-topics"
      />
      <RouteWithLayout component={Model} exact layout={Main} path={['/slots/:id', '/slots']} modelName="slots" />
      <RouteWithLayout component={Model} exact layout={Main} path={['/users/:id', '/users']} modelName="users" />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/resource-hubs/:id', '/resource-hubs']}
        modelName="resource-hubs"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/resource-categories/:id', '/resource-categories']}
        modelName="resource-categories"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/resources/:id', '/resources']}
        modelName="resources"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/resource-teasers/:id', '/resource-teasers']}
        modelName="resource-teasers"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/resource-videos/:id', '/resource-videos']}
        modelName="resource-videos"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/resource-pdfs/:id', '/resource-pdfs']}
        modelName="resource-pdfs"
      />
      <RouteWithLayout component={Model} exact layout={Main} path={['/demos/:id', '/demos']} modelName="demos" />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/demo-contents/:id', '/demo-contents']}
        modelName="demo-contents"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/demo-videos/:id', '/demo-videos']}
        modelName="demo-videos"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/demo-teasers/:id', '/demo-teasers']}
        modelName="demo-teasers"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/demo-teasers/:id', '/demo-teasers']}
        modelName="demo-teasers"
      />
      <RouteWithLayout component={Model} exact layout={Main} path={['/talks/:id', '/talks']} modelName="talks" />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/talk-teasers/:id', '/talk-teasers']}
        modelName="talk-teasers"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/languages/:id', '/languages']}
        modelName="languages"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/districts/:id', '/districts']}
        modelName="districts"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/district-meshes/:id', '/district-meshes']}
        modelName="district-meshes"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/product-meshes/:id', '/product-meshes']}
        modelName="product-meshes"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/district-textures/:id', '/district-textures']}
        modelName="district-textures"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/products/:id', '/products']}
        modelName="products"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/product-contents/:id', '/product-contents']}
        modelName="product-contents"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/lightmaps/:id', '/lightmaps']}
        modelName="lightmaps"
      />
      <RouteWithLayout component={Model} exact layout={Main} path={['/quizzes/:id', '/quizzes']} modelName="quizzes" />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/quiz-questions/:id', '/quiz-questions']}
        modelName="quiz-questions"
      />
      <RouteWithLayout
        component={Model}
        exact
        layout={Main}
        path={['/quiz-answers/:id', '/quiz-answers']}
        modelName="quiz-answers"
      />
      <RouteWithLayout component={Model} exact layout={Main} path={['/application']} modelName="application" />
      <RouteWithLayout component={Model} exact layout={Main} path={['/s-3']} modelName="s-3" />
      <RouteWithLayout component={Model} exact layout={Main} path={['/postmark']} modelName="postmark" />
      <RouteWithLayout component={Typography} exact layout={Main} path="/typography" />
      <RouteWithLayout component={Icons} exact layout={Main} path="/icons" />
      <RouteWithLayout component={Account} exact layout={Main} path="/account" />
      <RouteWithLayout component={Settings} exact layout={Main} path="/settings" />
      <RouteWithLayout component={Tools} exact layout={Main} path="/tools" />
      <Redirect exact from="/sign-in" to="/" />
      <RouteWithLayout component={NotFound} exact layout={Main} path="/not-found" />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
