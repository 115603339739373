import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  TextField,
  MenuItem,
  InputLabel,
  Select,
} from '@material-ui/core';

import { socketApi } from '../../../../services/SocketService';
import axios from 'axios';
import useCMS from '../../useCMS';

function lobbyWalk(user) {
  const identifier = user.email;
  const password = user.email.substring(user.email.indexOf('-') + 1, user.email.indexOf('-') + 9);
  const socket = socketApi.getState().createClientSocket();

  socket.emit('user/login', { credentials: { identifier, password } }, async loginResult => {
    socket.emit('district/pick', 'lobby', () => {});

    const { user } = loginResult;

    let rad = Math.random() * Math.PI * 2;
    const position = [0, 0, 0];
    let rotation = 0;

    function update() {
      rotation = rad + Math.PI / 2;
      position[0] = Math.sin(rad) * 10 - 10;
      position[1] = -1.7;
      position[2] = Math.cos(rad) * 15 - 20;
    }
    update();

    socket.emit('player/init', {
      position: [...position, rotation],
      reaction: 0,
    });

    let mode = 'idle';
    setInterval(() => {
      switch (mode) {
        case 'idle': {
          if (Math.random() < 0.01) {
            socket.emit('player/reaction', 0);
            mode = 'walk';
          }
          if (Math.random() < 0.03) {
            socket.emit('player/reaction', 2);
            socket.emit('message/emoji', { text: 'love', chatIdentifier: '1_lobby', userId: user.id });
          }
          break;
        }
        case 'walk': {
          rad += 0.01;
          update();
          socket.emit('player/position', [...position, rotation]);
          if (Math.random() < 0.01) mode = 'idle';
          break;
        }
      }
    }, 50);
  });
}

function stageWalk(user) {
  const identifier = user.email;
  const password = user.email.substring(user.email.indexOf('-') + 1, user.email.indexOf('-') + 9);
  const socket = socketApi.getState().createClientSocket();

  socket.emit('user/login', { credentials: { identifier, password } }, async loginResult => {
    socket.emit('district/switch', 'auditorium', () => {});

    const { user } = loginResult;

    let rad = Math.random() * Math.PI * 2;
    const position = [0, 0, 0];
    let rotation = 0;

    function update() {
      rotation = rad + Math.PI / 2;
      position[0] = Math.sin(rad) * 14 - 0;
      position[1] = 0;
      position[2] = Math.cos(rad) * 3 - 3.5;
    }
    update();

    socket.emit('player/init', {
      position: [...position, rotation],
      reaction: 0,
    });

    let mode = 'idle';
    setInterval(() => {
      switch (mode) {
        case 'idle': {
          if (Math.random() < 0.01) {
            socket.emit('player/reaction', 0);
            mode = 'walk';
          }
          if (Math.random() < 0.001) {
            socket.emit('player/reaction', 2);
            socket.emit('message/emoji', { text: 'love', chatIdentifier: '1_auditorium', userId: user.id });
          }
          break;
        }
        case 'walk': {
          rad += 0.01;
          update();
          socket.emit('player/position', [...position, rotation]);
          if (Math.random() < 0.01) mode = 'idle';
          break;
        }
      }
    }, 50);
  });
}

function stageStand(user) {
  const identifier = user.email;
  const password = user.email.substring(user.email.indexOf('-') + 1, user.email.indexOf('-') + 9);
  const socket = socketApi.getState().createClientSocket();

  socket.emit('user/login', { credentials: { identifier, password } }, async loginResult => {
    socket.emit('district/switch', 'auditorium', () => {});

    const { user } = loginResult;

    const radius = Math.pow(Math.random(), 0.5);
    let rad = Math.random() * Math.PI * 2;
    const position = [0, 0, 0];
    let rotation = 0;

    function update() {
      rotation = rad + Math.PI / 2;
      position[0] = Math.sin(rad) * 14 * radius - 0;
      position[1] = 0;
      position[2] = Math.cos(rad) * 3 * radius - 3.5;
    }
    update();

    socket.emit('player/init', {
      position: [...position, rotation],
      reaction: 0,
    });

    let mode = 'idle';
    setInterval(() => {
      switch (mode) {
        case 'idle': {
          if (Math.random() < 0.0001) {
            socket.emit('player/reaction', 0);
            mode = 'walk';
          }
          if (Math.random() < 0.03) {
            socket.emit('player/reaction', 2);
            socket.emit('message/emoji', { text: 'love', chatIdentifier: '1_auditorium', userId: user.id });
          }
          break;
        }
        case 'walk': {
          rad += 0.01;
          update();
          socket.emit('player/position', [...position, rotation]);
          if (Math.random() < 0.01) mode = 'idle';
          break;
        }
      }
    }, 50);
  });
}

function spamReactions(user, interval = 100) {
  const identifier = user.email;
  const password = user.email.substring(user.email.indexOf('-') + 1, user.email.indexOf('-') + 9);
  const socket = socketApi.getState().createClientSocket();

  socket.emit('user/login', { credentials: { identifier, password } }, async loginResult => {
    socket.emit('district/pick', 'lobby', () => {});

    const { user } = loginResult;

    socket.emit('player/init', {
      position: [Math.random() * -5, 0, Math.random() * 4 - 2, 0],
      reaction: 0,
    });

    let lastReactionTimestamp = 0;
    const reactionDuration = 3 * 1000;
    const emojis = ['like', 'love', 'clap', 'smile', 'celebration', 'greet'];

    setInterval(() => {
      const now = Date.now();
      const random = Math.random() * 6;
      if (now - lastReactionTimestamp > reactionDuration) {
        lastReactionTimestamp = now;
        socket.emit('player/reaction', Math.floor(1 + random));
        setTimeout(() => {
          socket.emit('player/reaction', 0);
        }, reactionDuration);
      }

      socket.emit('message/emoji', { text: emojis[Math.floor(random)], chatIdentifier: '1_lobby', userId: user.id });
    }, interval);
  });
}

async function eachUser(endpoint, auth, botAmount, method) {
  const allUsersResult = await axios.get(`${endpoint}/users?_limit=${botAmount}&email_contains=botbotbot`, auth);
  const allUsers = allUsersResult.data;

  for (const userIndex in allUsers) {
    const user = allUsers[userIndex];
    try {
      const isBotRequest = await axios.get(`${endpoint}/bot/is/${user.id}`, auth);
      if (isBotRequest.data.isBot) {
        // eslint-disable-next-line no-console
        console.log('run', user);
        method(user);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
  // eslint-disable-next-line no-console
  console.log('done');
}
const Bots = () => {
  const { endpoint, auth } = useCMS();
  const [botAmount, setBotAmount] = React.useState(1);

  const handleBotAmountChange = event => {
    setBotAmount(event.target.value);
  };

  return (
    <Card>
      <form>
        <CardHeader title="Bots" subheader="Run Bots" />
        <Divider />
        <CardActions>
          <Select labelId="label" id="select" value={botAmount} onChange={handleBotAmountChange}>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={200}>200</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </Select>
          <Button
            color="primary"
            variant="outlined"
            onClick={async () => {
              await eachUser(endpoint, auth, botAmount, lobbyWalk);
            }}
          >
            Lobby Walk
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={async () => {
              await eachUser(endpoint, auth, botAmount, stageWalk);
            }}
          >
            Stage Walk
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={async () => {
              await eachUser(endpoint, auth, botAmount, stageStand);
            }}
          >
            Stage Stand
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={async () => {
              await eachUser(endpoint, auth, botAmount, user => spamReactions(user, 100));
            }}
          >
            Spam Reactions (each 100ms)
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={async () => {
              await eachUser(endpoint, auth, botAmount, user => spamReactions(user, 500));
            }}
          >
            Spam Reactions (each 500ms)
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={async () => {
              await eachUser(endpoint, auth, botAmount, user => spamReactions(user, 1000));
            }}
          >
            Spam Reactions (each 1000ms)
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default Bots;
