import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import Users from './components/Users/Users';
import Accounts from './components/Accounts/Accounts';
import Slots from './components/Slots/Slots';
import Bots from './components/Bots/Bots';
import Export from './components/Export';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Tools = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={4} xs={12}>
          <Users />
        </Grid>
        <Grid item md={4} xs={12}>
          <Accounts />
        </Grid>
        <Grid item md={4} xs={12}>
          <Bots />
        </Grid>
        <Grid item md={4} xs={12}>
          <Slots />
        </Grid>
        <Grid item md={4} xs={12}>
          <Export />
        </Grid>
      </Grid>
    </div>
  );
};

export default Tools;
