import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import Toolbar from './components/Toolbar/Toolbar';
import Table from './components/Table/Table';

import { useModelStore } from 'services/ModelService';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const ModelList = () => {
  const schema = useModelStore(state => state.schema);

  const classes = useStyles();

  const history = useHistory();
  const handleCreate = () => {
    console.log(`/${schema.routeName}/new`);
    history.push(`/${schema.routeName}/new`);
  };

  const buttons = schema.createHidden
    ? []
    : [
        <Button key={'create'} color="primary" variant="contained" onClick={handleCreate}>
          Create
        </Button>,
      ];

  return (
    <div className={classes.root}>
      <Toolbar headline={schema.modelName} buttons={buttons} hasSearch={true} />
      <div className={classes.content}>
        <Table />
      </div>
    </div>
  );
};

export default ModelList;
