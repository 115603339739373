import React from 'react';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import capitalize from 'helpers/capitalize';

import { modelApi } from 'services/ModelService';

export function DropdownField({ instance, attribute, attributeName, options }) {

  const value = instance[attributeName];
  const label = capitalize(attributeName);

  const handleChange = event => {
    instance[attributeName] = event.target.value;
    modelApi.setState({ instance: { ...instance } });
  };

  return (
    <FormControl fullWidth>
      <InputLabel shrink>{label}</InputLabel>
      <Select value={value} onChange={handleChange} displayEmpty>
        {options}
      </Select>
    </FormControl>
  );
}
