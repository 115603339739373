import Storage from 'utilities/storage';

const CMS_TARGET = 'CMS_TARGET';
const SOCKET_TARGET = 'SOCKET_TARGET';

const storage = new Storage();
storage.init('live_debug_1');
storage.defineEntry(CMS_TARGET, 'AUTO');
storage.defineEntry(SOCKET_TARGET, 'AUTO');

export default {
  setCMSTarget: target => {
    storage.setItem(CMS_TARGET, target);
  },
  getCMSTarget: () => {
    return storage.getItem(CMS_TARGET);
  },
  setSocketTarget: target => {
    storage.setItem(SOCKET_TARGET, target);
  },
  getSocketTarget: () => {
    return storage.getItem(SOCKET_TARGET);
  },
};
