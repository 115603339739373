import moment from 'moment-timezone';

const defaultTz = 'UTC';
const dateTime = 'YYYY-MM-DD HH:mm:ss';

export function utcToLocal(utcString, timeZone) {
  return moment.tz(utcString, timeZone || defaultTz).format(dateTime);
}

export function localToUtc(timeStamp, timeZone) {
  const t = timeStamp.format(dateTime || defaultTz);
  return moment.tz(t, timeZone).utc();
}
