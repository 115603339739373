/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Link, List, ListSubheader, Typography, makeStyles } from '@material-ui/core';

import {
  AlertCircle as AlertCircleIcon,
  AlignLeft as AlignLeftIcon,
  Calendar as CalendarIcon,
  Clock as ClockIcon,
  Settings as SettingsIcon,
  Tool as ToolIcon,
  Users as UsersIcon,
  File as FileIcon,
  Package as PackageIcon,
  Phone as PhoneIcon,
  Server as ServerIcon,
  Film as FilmIcon,
  Image as ImageIcon,
} from 'react-feather';

import { useUserStore } from 'services/UserService';

import NavItem from './NavItem';

const settingsConfig = {
  title: 'Settings',
  icon: SettingsIcon,
  href: '/settings',
};

const navConfig = [
  {
    subheader: 'Content',
    items: [
      {
        title: 'Events',
        icon: CalendarIcon,
        href: '/events',
      },
      {
        title: 'Auditorium Talks',
        icon: ServerIcon,
        href: '/talks',
        items: [
          {
            title: 'Talks',
            icon: ServerIcon,
            href: '/talks',
          },
          {
            title: 'Teasers',
            icon: ImageIcon,
            href: '/talk-teasers',
          },
        ],
      },
      {
        title: 'Resource Hub',
        icon: PackageIcon,
        href: '/resource-hubs',
        items: [
          {
            title: 'Hubs',
            icon: ServerIcon,
            href: '/resource-hubs',
          },
          {
            title: 'Categories',
            icon: AlignLeftIcon,
            href: '/resource-categories',
          },
          {
            title: 'Resources',
            icon: FileIcon,
            href: '/resources',
          },
          {
            title: 'Teasers',
            icon: ImageIcon,
            href: '/resource-teasers',
          },
          // {
          //   title: 'Videos',
          //   icon: ImageIcon,
          //   href: '/resource-videos',
          // },
          // {
          //   title: 'Files',
          //   icon: FileIcon,
          //   href: '/resource-files',
          // },
        ],
      },
      {
        title: 'Products',
        icon: PhoneIcon,
        href: '/products',
        items: [
          {
            title: 'Products',
            icon: PackageIcon,
            href: '/products',
          },
          {
            title: 'Meshes',
            icon: PackageIcon,
            href: '/product-meshes',
          },
          {
            title: 'Contents',
            icon: PackageIcon,
            href: '/product-contents',
          },
        ],
      },
      {
        title: 'Districts',
        icon: PackageIcon,
        href: '/districts',
        items: [
          {
            title: 'Districts',
            icon: PackageIcon,
            href: '/districts',
          },
          {
            title: 'Meshes',
            icon: PackageIcon,
            href: '/district-meshes',
          },
          {
            title: 'Textures',
            icon: ImageIcon,
            href: '/district-textures',
          },
          {
            title: 'Lightmaps',
            icon: ImageIcon,
            href: '/lightmaps',
          },
        ],
      },
      {
        title: 'Quiz',
        icon: PackageIcon,
        href: '/quizzes',
        items: [
          {
            title: 'Quiz',
            icon: PackageIcon,
            href: '/quizzes',
          },
          {
            title: 'Questions',
            icon: PackageIcon,
            href: '/quiz-questions',
          },
          {
            title: 'Answers',
            icon: PackageIcon,
            href: '/quiz-answers',
          },
        ],
      },
      {
        title: 'Users',
        icon: UsersIcon,
        href: '/users',
      },
    ],
  },
  {
    subheader: 'Administration',
    items: [
      {
        title: 'Setup',
        icon: SettingsIcon,
        items: [
          {
            title: 'Languages',
            href: '/languages',
          },
          {
            title: 'Application',
            href: '/application',
          },
          {
            title: 'S3',
            href: '/s-3',
          },
          {
            title: 'Postmark',
            href: '/postmark',
          },
        ],
      },
      {
        title: 'Tools',
        icon: ToolIcon,
        href: '/tools',
      },
      settingsConfig,
    ],
  },
];

const loggedOutConfig = [
  {
    subheader: 'Administration',
    items: [settingsConfig],
  },
];

function renderNavItems({ items, ...rest }) {
  return <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, ...rest }), [])}</List>;
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem depth={depth} icon={item.icon} key={key} info={item.info} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} key={key} info={item.info} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 206,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const isLoggedIn = useUserStore(state => state.loginState === 'LOGGED_IN');

  const config = isLoggedIn ? navConfig : loggedOutConfig;

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Divider />
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {config.map(config => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
        {content}
      </Drawer>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
