import io from 'socket.io-client';
import { Encoder, Decoder } from './schemapack-parser';
import create from 'zustand';
import { AUTO, DEVELOPMENT, LOCAL, PRODUCTION, buildApi } from 'services/BuildService';
import debugStore from 'storage/debug';

const ENDPOINTS = {
  [LOCAL]: 'http://localhost:5000/',
  [DEVELOPMENT]: 'https://develop.hub.redminote11.de/',
  [PRODUCTION]: 'https://hub.redminote11.de/',
};

const INTEGRATION_TARGETS = {
  [LOCAL]: ENDPOINTS[DEVELOPMENT],
  [DEVELOPMENT]: ENDPOINTS[DEVELOPMENT],
  [PRODUCTION]: ENDPOINTS[PRODUCTION],
};

export const [useSocketStore, socketApi] = create((set, get) => ({
  socket: null,
  url: '',
  hubId: null,
  path: '',

  selectEndpoint: hubId => {
    const { integration } = buildApi.getState();
    const debugSocketTarget = debugStore.getSocketTarget();
    const target = debugSocketTarget === 'AUTO' ? integration : debugSocketTarget;
    if (hubId !== null) {
      const path = `${hubId}/`;
      set({ hubId, path });
    } else {
      set({ hubId: null, path: '' });
      // eslint-disable-next-line no-console
      console.error('Hub instancing not available. Using default endpoint.');
    }
    const url = new URL(debugSocketTarget === 'AUTO' ? INTEGRATION_TARGETS[integration] : ENDPOINTS[debugSocketTarget]);

    if (target === LOCAL) {
      url.port = `${parseInt(url.port) + parseInt(hubId)}`;
    }

    set({ url: url.toString() });
  },

  connect: () => {
    const { url: targetUrl, hubId } = get();
    const url = new URL(targetUrl);
    console.log('connect', hubId, url.origin);
    const socket = io(url.origin + '?protocol=v6&admin=true', {
      path: hubId === null ? null : `/${hubId}/socket.io/`,
      parser: { Encoder, Decoder },
      transports: ['websocket'],
    });
    set({ socket });
  },

  disconnect: () => {
    const { socket } = get();
    if (socket) {
      socket.removeAllListeners();
      socket.disconnect();
    }
    set({ socket: null });
  },

  createClientSocket: () => {
    const { url: targetUrl, hubId } = get();
    const url = new URL(targetUrl);
    console.log('clientSocket', hubId, url.origin);
    const socket = io(url.origin + '?protocol=v6', {
      path: hubId === null ? null : `/${hubId}/socket.io/`,
      parser: { Encoder, Decoder },
      transports: ['websocket'],
    });

    return socket;
  },
}));
