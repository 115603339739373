import create from 'zustand';
import { cmsApi } from 'services/CmsService';
import axios from 'axios';
import userStore from 'storage/user';

function context() {
  const { endpoint } = cmsApi.getState();
  const token = userStore.getToken();
  const auth = { headers: { Authorization: `Bearer ${token}` } };
  return { endpoint, auth };
}

export const [useEventStore, eventApi] = create(set => ({
  instance: null,
  events: [],

  pick: async () => {
    const { endpoint } = context();
    const result = await axios.post(`${endpoint}/events/pick`);
    const instance = result.data ? result.data.event : null;
    set({ instance });
    return instance;
  },

  getEvents: async () => {
    const { endpoint, auth } = context();
    const result = await axios.get(`${endpoint}/events`, auth);
    const events = result.data ? result.data : [];
    set({ events });
  },
}));
