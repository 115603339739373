import React, { useEffect, useState } from 'react';
import { TextField, FormControl } from '@material-ui/core';
import { modelApi } from 'services/ModelService';

export function ProviderPayload({ instance }) {
  const { providerType } = instance;
  const [currentType, setCurrentType] = useState(providerType);
  useEffect(() => {
    if (!instance.providerPayload || currentType !== providerType) {
      setCurrentType(providerType);
      instance.providerPayload = {};
      modelApi.setState({ instance: { ...instance } });
    }
  }, [providerType]);
  if (!providerType) {
    return null;
  }
  if (!instance.providerPayload) {
    return null;
  }
  switch (currentType) {
    case 'HLS_STREAM': {
      const handleChange = event => {
        instance.providerPayload = {
          liveStream: {
            ...instance.providerPayload.liveStream,
            url: event.target.value,
          },
        };
        modelApi.setState({ instance: { ...instance } });
      };
      return (
        <FormControl fullWidth>
          <TextField
            onChange={handleChange}
            fullWidth
            helperText={''}
            label={'URL'}
            type={'text'}
            variant="outlined"
            value={instance.providerPayload.liveStream && instance.providerPayload.liveStream.url}
          />
        </FormControl>
      );
    }
    default:
      return null;
  }
}
