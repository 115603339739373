import React, { Component, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';

import { useBuildStore } from 'services/BuildService';
import { useUserStore } from 'services/UserService';
import { useCmsStore } from 'services/CmsService';
import { useSocketStore } from 'services/SocketService';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

validate.validators = {
  ...validate.validators,
  ...validators,
};

const AppComponent = () => {
  const selectEndpoint = useSocketStore(state => state.selectEndpoint);
  const initBuildService = useBuildStore(state => state.init);
  const initCmsService = useCmsStore(state => state.init);
  const initUserService = useUserStore(state => state.init);
  const getBackendStatus = useBuildStore(state => state.getBackendStatus);

  const [ready, setReady] = React.useState(false);

  useEffect(() => {
    initBuildService();
    initCmsService();
    getBackendStatus().then(({ hubId }) => {
      selectEndpoint(hubId);
      initUserService();
      setReady(true);
    });
  }, []);

  return ready ? (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  ) : null;
};

export default class App extends Component {
  render() {
    return <AppComponent />;
  }
}
