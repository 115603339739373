import create from 'utilities/zustand/create';
import { cmsApi } from 'services/CmsService';
import axios from 'axios';

export const AUTO = 'AUTO';
export const LOCAL = 'LOCAL';
export const DEVELOPMENT = 'DEVELOPMENT';
export const PRODUCTION = 'PRODUCTION';
export const ALL_INTEGRATIONS = [AUTO, LOCAL, DEVELOPMENT, PRODUCTION];

const INTEGRATIONS = {
  // eslint-disable-next-line no-useless-computed-key
  ['localhost']: LOCAL,
  // eslint-disable-next-line no-useless-computed-key
  ['develop.dashboard.redminote11.de']: DEVELOPMENT,
  // eslint-disable-next-line no-useless-computed-key
  ['dashboard.redminote11.de']: PRODUCTION,
};

export const [useBuildStore, buildApi] = create(module, set => ({
  integration: '',
  init: () => {
    const integration = INTEGRATIONS[window.location.hostname] || DEVELOPMENT;
    set({ integration });
  },

  getBackendStatus: async () => {
    const backendUrl = cmsApi.getState().endpoint;
    let data;
    try {
      const response = await axios.get(backendUrl + '/status');
      data = response.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    if (data) {
      const { status, hubId } = data;
      return { status, hubId };
    }
    return {};
  },
}));
