import create from 'utilities/zustand/create';
import userStore from 'storage/user';
import { cmsApi } from 'services/CmsService';
import { socketApi } from 'services/SocketService';
import axios from 'axios';

export const [useUserStore, userApi] = create(module, (set, get) => ({
  user: null,
  loginState: 'PENDING',

  init: async () => {
    const token = userStore.getToken();
    if (token) {
      await get().login({ token });
    } else {
      set({ loginState: 'LOGGED_OUT' });
    }
  },

  login: payload => {
    set({ payload: 'PENDING' });
    const { endpoint } = cmsApi.getState();

    const { token, credentials } = payload;

    const fail = error => {
      console.log(error);
      let message;

      try {
        message = error.response.data.message[0].messages[0].message;
        // eslint-disable-next-line no-empty
      } catch (e) {}

      try {
        message = message || error.response.data.error;
        // eslint-disable-next-line no-empty
      } catch (e) {}

      try {
        message = message || error.response.statusText;
        // eslint-disable-next-line no-empty
      } catch (e) {}

      get().disconnect({ copy: message ? message.toString() : null });
    };

    socketApi.getState().connect();

    return new Promise(resolve => {
      const success = data => {
        const { user, jwt } = data;
        if (!['admin'].includes(user.role.type)) {
          // it's safe because every critical API should be secured with the role check
          return get().disconnect({ copy: 'you need the permission to access the dashboard' });
        }
        userStore.setToken(jwt);
        set({ user, loginState: 'LOGGED_IN' });
        resolve();
      };

      if (token) {
        axios
          .get(`${endpoint}/users/me`, { headers: { Authorization: 'Bearer ' + token } })
          .then(response => {
            success({ user: response.data, jwt: token });
          })
          .catch(fail);
      } else if (credentials) {
        axios
          .post(`${endpoint}/auth/local`, credentials)
          .then(response => {
            success(response.data);
          })
          .catch(fail);
      }
    });
  },

  logout: () => {
    userStore.setToken(null);
    get().disconnect();
    set({
      user: null,
    });
    socketApi.getState().disconnect();
  },

  disconnect: message => {
    set({ loginState: 'LOGGED_OUT', message });
  },
}));
