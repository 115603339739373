import React from 'react';
import { Button, Card, CardActions, CardHeader, Divider } from '@material-ui/core';
import axios from 'axios';
import useCMS from '../../useCMS';

const Slots = () => {
  const { endpoint, auth } = useCMS();

  const fixIsBookedUp = async () => {
    const allSlotsResult = await axios.get(`${endpoint}/slots?_limit=-1`, auth);
    for (let i = 0; i < allSlotsResult.data.length; i++) {
      const slot = allSlotsResult.data[i];
      try {
        const slotUpdate = {};
        const updateResult = await axios.put(`${endpoint}/slots/${slot.id}`, slotUpdate, auth);
        console.log(i + '/' + allSlotsResult.data.length, slot.isBookedUp, '=>', updateResult.data.isBookedUp);
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <Card>
      <form>
        <CardHeader title="Slots" subheader="Manage Slots" />
        <Divider />
        <CardActions>
          <Button color="primary" variant="outlined" onClick={fixIsBookedUp}>
            Fix isBookedUp
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default Slots;
