import React, { useEffect, useRef } from 'react';
import { Button, Card, CardActions, CardHeader, Divider } from '@material-ui/core';
import axios from 'axios';
import { socketApi } from '../../../../services/SocketService';
import useCMS from '../../useCMS';

const Accounts = () => {
  const adminSocket = useRef(null);

  const { endpoint, auth } = useCMS();

  useEffect(() => {
    const { socket } = socketApi.getState();
    adminSocket.current = socket;
  }, []);

  function createAccounts(amount) {
    return () => {
      // eslint-disable-next-line no-console
      console.log('create', amount);
      let index = amount;
      const next = () => {
        if (index === 0) {
          // eslint-disable-next-line no-console
          console.log('done');
          return;
        }
        index--;
        adminSocket.current.emit('admin/registerBot', {}, e => {
          // eslint-disable-next-line no-console
          console.log(amount - index, amount, e);
          next();
        });
      };
      next();
    };
  }

  return (
    <Card>
      <form>
        <CardHeader title="Accounts" subheader="Add bot accounts" />
        <Divider />
        <CardActions>
          <Button color="primary" variant="outlined" onClick={createAccounts(1)}>
            1
          </Button>
          <Button color="primary" variant="outlined" onClick={createAccounts(10)}>
            10
          </Button>
          <Button color="primary" variant="outlined" onClick={createAccounts(100)}>
            100
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={async () => {
              const allUsersResult = await axios.get(`${endpoint}/users?_limit=-1&email_contains=botbotbot`, auth);
              const allUsers = allUsersResult.data;
              console.log('done:', allUsers.length, 'bots found');
            }}
          >
            List Bots
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={async () => {
              const allUsersResult = await axios.get(`${endpoint}/users?_limit=-1&email_contains=botbotbot`, auth);
              const allUsers = allUsersResult.data;
              let count = 0;
              for (const userIndex in allUsers) {
                count++;
                console.log(count, allUsers.length);
                const user = allUsers[userIndex];
                try {
                  const bot = await axios.get(`${endpoint}/bot/is/${user.id}`, auth);
                  if (bot.data.isBot) {
                    const deleteUpdate = await axios.delete(`${endpoint}/bot/${user.id}`, auth);
                    console.log(deleteUpdate.data);
                  }
                } catch (e) {
                  console.error(e);
                }
              }
              console.log('done');
            }}
          >
            Delete Bots
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default Accounts;
