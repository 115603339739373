import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';

import ModelList from './ModelList';
import ModelForm from './ModelForm';

import { modelApi, useModelStore } from 'services/ModelService';

const useStyles = makeStyles(theme => ({
  alignItemsAndJustifyContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Model = params => {
  const match = useRouteMatch();
  const id = match.params.id;

  const classes = useStyles();

  let routeName = params.location.pathname.substr(1);
  let routeParts = routeName.split('/');
  const lastRoutePart = routeParts[routeParts.length - 1];
  const isNumeric = !isNaN(lastRoutePart);
  if (lastRoutePart === 'new' || isNumeric) {
    routeParts.pop();
    routeName = routeParts.join('/');
  }

  const modelName = params.modelName;
  const schema = useModelStore(state => state.schema);

  useEffect(() => {
    modelApi.getState().init(modelName, routeName);
    return () => {
      modelApi.getState().shutdown();
    };
  }, [modelName]);

  if (schema == null) {
    return (
      <div className={classes.alignItemsAndJustifyContent}>
        <CircularProgress color="primary" size={100} />
      </div>
    );
  }

  if (schema.kind === 'singleType') {
    return <ModelForm modelName={modelName} />;
  } else {
    return (
      <>
        {id == null && <ModelList modelName={modelName} />}
        {id != null && <ModelForm modelName={modelName} id={match.params.id} />}
      </>
    );
  }
};

export default Model;
